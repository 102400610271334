import { DownloadOutlined } from "@ant-design/icons";
import { TableIcon } from "@components/table/tableStyle";
import { Button, message } from "antd";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect } from "react";

interface ExportToCsvButtonProps {
    exportFileName: string;
    columnNames: string[];
    mapEntryToCsvRow: (data: Record<string, any>) => string;
    lazyDataQuery: (props?: unknown) => void;
    dataToDownload: Record<string, any>[] | undefined;
    loading: boolean;
    downloadedElementId?: string;
    queryVariables?: Record<string, any>;
}

export const ExportToCsvButton: React.FC<ExportToCsvButtonProps> = (props) => {
    const {
        exportFileName,
        columnNames,
        mapEntryToCsvRow,
        lazyDataQuery,
        dataToDownload,
        loading,
        downloadedElementId,
        queryVariables
    } = props;

    const intl = useIntl();

    const cacheFile = React.useRef<HTMLAnchorElement | undefined>();

    const mapDataToCSV = (entries: Record<string, any>[]): string => {
        const exportDataColumns = `${columnNames.join(";")}\n`;
        const dataToDownloadMapped = entries.map(mapEntryToCsvRow);

        return exportDataColumns + dataToDownloadMapped.join("\n");
    };

    const handleDownloadCSV = (exportData: string): void => {
        const hiddenElement = document.createElement("a");
        const blob = new Blob(["\ufeff", exportData], { type: "text/csv;charset=utf-8" });
        hiddenElement.href = window.URL.createObjectURL(blob);
        hiddenElement.download = `${exportFileName}${downloadedElementId ? `_${downloadedElementId}` : ""}.csv`;
        hiddenElement.click();
        cacheFile.current = hiddenElement;
    };

    React.useEffect(() => {
        if (dataToDownload) {
            try {
                const exportDataCSV = mapDataToCSV(dataToDownload);

                handleDownloadCSV(exportDataCSV);
            } catch (error) {
                message.error(`${intl.formatMessage({ id: "error.csvexport" })}, ${error}`);
            }
        }
    }, [dataToDownload]);

    const handleExport = async (): Promise<void> => {
        if (cacheFile.current) {
            cacheFile.current.click();
            return;
        }
        await lazyDataQuery({
            ...(queryVariables || {})
        });
    };

    useEffect(() => {
        cacheFile.current = undefined;
    }, [queryVariables]);

    return (
        <Button
            ghost
            className="headerButton"
            loading={loading}
            type="primary"
            onClick={handleExport}
        >
            <TableIcon as={DownloadOutlined} />
            <FormattedMessage id="exportToCSV" />
        </Button>
    );
};
